import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Ian MacLeod, chief technologist at Geosoft Inc., was recently honoured with the
Cecil Green  Entreprise Award from the Society of Exploration Geophysicists. The
award took place at the  SEG's annual convention, held this year in Houston in
September. Geosoft's news release can be found `}<a parentName="p" {...{
        "href": "/"
      }}>{`here`}</a>{`. `}</p>
    <p>{`The citation for the award was written by Colin Reeves who was associated with
Ian in the  formative days of Geosoft when the first IBM-PC (1981) heralded the
arrival of a new age of  computing that we have come to take for granted. Before
then computing was the preserve of  dedicated data centres that lacked much in
the way of easy communication with users. As a  consequence, iterative
interpretation processes - not to mention other geophysical data processing  and
map production procedures - were tedious and time-consuming.
Colin recalls some memories of these early days in the citation. `}<a parentName="p" {...{
        "href": "/"
      }}>{`Citation`}</a>{`
(courtesy of SEG).`}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      